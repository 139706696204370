import React from 'react';

interface TaskItemProps {
  title: string;
  time: string;
  onDetailsClick: (task: { title: string; time: string }) => void;
}

const TaskItem: React.FC<TaskItemProps> = ({ title, time, onDetailsClick }) => {
  return (
    <div className="flex items-center justify-between p-4 bg-gray-800 rounded-lg mb-2">
      <div className="flex items-center">
        <div className="w-6 h-6 border-2 border-white rounded-full mr-4"></div>
        <div onClick={() => onDetailsClick({ title, time })}>
          <div className="text-white font-bold">{title}</div>
          <div className="text-gray-500">{time}</div>
        </div>
      </div>
      <button className="text-red-500">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 6h18M9 6V4a1 1 0 011-1h4a1 1 0 011 1v2m3 0v13a2 2 0 01-2 2H8a2 2 0 01-2-2V6h12z"
          />
        </svg>
      </button>
    </div>
  );
};

export default TaskItem;  