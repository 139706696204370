import React from 'react';
import { format, startOfMonth, endOfMonth, startOfWeek, endOfWeek, addDays, isSameMonth, isSameDay } from 'date-fns';

const Calendar: React.FC = () => {
  const currentDate = new Date();
  const monthStart = startOfMonth(currentDate);
  const monthEnd = endOfMonth(monthStart);
  const startDate = startOfWeek(monthStart);
  const endDate = endOfWeek(monthEnd);

  const rows = [];
  let days = [];
  let day = startDate;
  let formattedDate = '';

  while (day <= endDate) {
    for (let i = 0; i < 7; i++) {
      formattedDate = format(day, 'd');
      const cloneDay = day;
      days.push(
        <td
          key={day.toString()}
          className={`text-xs ${!isSameMonth(day, monthStart) ? 'text-gray-500' : isSameDay(day, currentDate) ? 'text-white' : 'text-gray-400'}`}
        >
          {formattedDate}
        </td>
      );
      day = addDays(day, 1);
    }
    rows.push(
      <tr key={day.toString()}>{days}</tr>
    );
    days = [];
  }

  return (
    <div className="text-white text-center py-7 flex justify-center items-center">
      <div  style={{width: "110px"}}>
        <div className="text-2xl font-semibold">{format(currentDate, 'EEE').toUpperCase()}</div>
        <div className="text-5xl font-semibold">{format(currentDate, 'd')}</div>
        <div className="text-1xl tracking-widest mt-2">{format(currentDate, 'MMMM').toUpperCase()}</div>
      </div>
      <div style={{width: "180px"}}>
        <table className="w-full">
          <thead>
            <tr className="text-lg">
              <th className="text-xs">M</th>
              <th className="text-xs">T</th>
              <th className="text-xs">W</th>
              <th className="text-xs">T</th>
              <th className="text-xs">F</th>
              <th className="text-xs">S</th>
              <th className="text-xs">S</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Calendar;