import React, { useState } from 'react';

const BottomMenu: React.FC = () => {
  const [isPopupVisible, setPopupVisible] = useState(false);

  const togglePopup = () => {
    setPopupVisible(!isPopupVisible);
  };

  return (
    <div className="bottom-0 left-0 right-0 bg-gray-800 py-4 flex justify-around items-center border-t border-blue-700">
      <div className="flex flex-col items-center w-1/5">
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.8383 6.87167L13.1717 18.5383C13.0637 18.6465 12.9355 18.7323 12.7944 18.7908C12.6533 18.8494 12.502 18.8795 12.3492 18.8795C12.1964 18.8795 12.0451 18.8494 11.9039 18.7908C11.7628 18.7323 11.6346 18.6465 11.5267 18.5383L8.22501 15.2367C8.117 15.1287 8.03132 15.0004 7.97286 14.8593C7.9144 14.7182 7.88432 14.5669 7.88432 14.4142C7.88432 14.2614 7.9144 14.1102 7.97286 13.969C8.03132 13.8279 8.117 13.6997 8.22501 13.5917C8.33302 13.4837 8.46125 13.398 8.60237 13.3395C8.7435 13.2811 8.89476 13.251 9.04751 13.251C9.20026 13.251 9.35152 13.2811 9.49264 13.3395C9.63377 13.398 9.762 13.4837 9.87001 13.5917L12.3433 16.065L23.1817 5.22667C23.2896 5.11851 23.4178 5.03271 23.5589 4.97416C23.7001 4.91562 23.8514 4.88548 24.0042 4.88548C24.157 4.88548 24.3083 4.91562 24.4494 4.97416C24.5905 5.03271 24.7187 5.11851 24.8267 5.22667C25.2933 5.68167 25.2933 6.41667 24.8383 6.87167ZM18.3983 3.19667C16.4267 2.39167 14.1867 2.11167 11.8533 2.53167C7.10501 3.38333 3.31334 7.21 2.50834 11.9583C2.1892 13.7674 2.30132 15.6262 2.83559 17.3838C3.36987 19.1414 4.31122 20.7481 5.58318 22.0735C6.85514 23.3989 8.42182 24.4055 10.1559 25.0116C11.8901 25.6177 13.7427 25.8061 15.5633 25.5617C20.1833 24.9667 24.0567 21.525 25.27 17.0333C25.7367 15.3183 25.7833 13.6617 25.515 12.11C25.3633 11.1767 24.2083 10.815 23.5317 11.48C23.2633 11.7483 23.1467 12.145 23.2167 12.5183C23.4733 14.07 23.3567 15.7267 22.61 17.4883C21.2567 20.65 18.3167 22.9717 14.8983 23.2867C8.94834 23.835 4.01334 18.795 4.74834 12.81C5.25001 8.68 8.57501 5.32 12.6933 4.74833C14.7117 4.46833 16.625 4.85333 18.2583 5.69333C18.5337 5.83721 18.855 5.8658 19.1514 5.77282C19.4479 5.67983 19.6953 5.47288 19.8392 5.1975C19.9831 4.92212 20.0116 4.60086 19.9187 4.30439C19.8257 4.00793 19.6187 3.76055 19.3433 3.61667C19.0283 3.47667 18.7133 3.325 18.3983 3.19667Z" fill="#4A90E2"/>
        </svg>
        <span className="text-blue-400 text-sm">Tasks</span>
      </div>
      <div className="flex flex-col items-center w-1/5">
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.6667 3.5H4.66667C4.35725 3.5 4.0605 3.62292 3.84171 3.84171C3.62292 4.0605 3.5 4.35725 3.5 4.66667V11.6667C3.5 11.9761 3.62292 12.2728 3.84171 12.4916C4.0605 12.7104 4.35725 12.8333 4.66667 12.8333H11.6667C11.9761 12.8333 12.2728 12.7104 12.4916 12.4916C12.7104 12.2728 12.8333 11.9761 12.8333 11.6667V4.66667C12.8333 4.35725 12.7104 4.0605 12.4916 3.84171C12.2728 3.62292 11.9761 3.5 11.6667 3.5ZM23.3333 3.5H16.3333C16.0239 3.5 15.7272 3.62292 15.5084 3.84171C15.2896 4.0605 15.1667 4.35725 15.1667 4.66667V11.6667C15.1667 11.9761 15.2896 12.2728 15.5084 12.4916C15.7272 12.7104 16.0239 12.8333 16.3333 12.8333H23.3333C23.6428 12.8333 23.9395 12.7104 24.1583 12.4916C24.3771 12.2728 24.5 11.9761 24.5 11.6667V4.66667C24.5 4.35725 24.3771 4.0605 24.1583 3.84171C23.9395 3.62292 23.6428 3.5 23.3333 3.5ZM11.6667 15.1667H4.66667C4.35725 15.1667 4.0605 15.2896 3.84171 15.5084C3.62292 15.7272 3.5 16.0239 3.5 16.3333V23.3333C3.5 23.6428 3.62292 23.9395 3.84171 24.1583C4.0605 24.3771 4.35725 24.5 4.66667 24.5H11.6667C11.9761 24.5 12.2728 24.3771 12.4916 24.1583C12.7104 23.9395 12.8333 23.6428 12.8333 23.3333V16.3333C12.8333 16.0239 12.7104 15.7272 12.4916 15.5084C12.2728 15.2896 11.9761 15.1667 11.6667 15.1667ZM19.8333 15.1667C20.7464 15.1667 21.6394 15.4345 22.4017 15.937C23.164 16.4395 23.7622 17.1546 24.1221 17.9937C24.482 18.8328 24.5879 19.7591 24.4266 20.6577C24.2653 21.5564 23.8439 22.3881 23.2146 23.0496C22.5854 23.7112 21.7759 24.1736 20.8864 24.3796C19.9969 24.5857 19.0665 24.5262 18.2104 24.2087C17.3544 23.8912 16.6103 23.3295 16.0703 22.5933C15.5303 21.857 15.2182 20.9786 15.1725 20.0667L15.1667 19.8333L15.1725 19.6C15.2324 18.4042 15.7495 17.2772 16.6171 16.452C17.4846 15.6268 18.636 15.1667 19.8333 15.1667Z" fill="white"/>
        </svg>
        <span className="text-white text-sm">Categories</span>
      </div>
      <div onClick={togglePopup} className="flex flex-col items-center w-1/5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="#4A90E2"
          className="w-12 h-12 mb-1"
        >
          <circle cx="12" cy="12" r="9" stroke="#4A90E2" strokeWidth="2" />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 8v8m-4-4h8"
          />
        </svg>
      </div>
      <div className="flex flex-col items-center w-1/5">
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2003_4)">
          <path d="M8.4805 3.62833C9.61683 2.492 11.5337 3.23167 11.6597 4.7845L11.6667 4.94783V23.3333C11.6664 23.7828 11.4933 24.2149 11.1832 24.5401C10.8731 24.8654 10.4497 25.0588 10.0008 25.0804C9.55186 25.102 9.11186 24.9501 8.77193 24.6561C8.432 24.3621 8.2182 23.9487 8.17483 23.5013L8.16667 23.3333V8.89117L6.48667 10.5712C6.16958 10.8894 5.74227 11.0735 5.29319 11.0854C4.84412 11.0972 4.40768 10.9359 4.07425 10.6349C3.74081 10.3338 3.53592 9.91607 3.50199 9.46812C3.46807 9.02017 3.60771 8.57633 3.892 8.2285L4.01217 8.0955L8.4805 3.62833ZM18.0833 2.91667C18.5184 2.91669 18.9378 3.07874 19.2598 3.37122C19.5819 3.6637 19.7834 4.06565 19.8252 4.49867L19.8333 4.66667V19.1088L21.5133 17.4288C21.8304 17.1106 22.2577 16.9265 22.7068 16.9146C23.1559 16.9028 23.5923 17.0641 23.9257 17.3651C24.2592 17.6662 24.4641 18.0839 24.498 18.5319C24.5319 18.9798 24.3923 19.4237 24.108 19.7715L23.9878 19.9045L19.5195 24.3717C18.3832 25.508 16.4663 24.7683 16.3403 23.2155L16.3333 23.0522V4.66667C16.3333 4.20254 16.5177 3.75742 16.8459 3.42923C17.1741 3.10104 17.6192 2.91667 18.0833 2.91667Z" fill="white"/>
          </g>
          <defs>
          <clipPath id="clip0_2003_4">
          <rect width="28" height="28" fill="white"/>
          </clipPath>
          </defs>
          </svg>
        <span className="text-white text-sm">Transfer</span>
      </div>
      <div className="flex flex-col items-center w-1/5">
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 11.6667C16.5773 11.6667 18.6667 9.57733 18.6667 7C18.6667 4.42267 16.5773 2.33333 14 2.33333C11.4227 2.33333 9.33333 4.42267 9.33333 7C9.33333 9.57733 11.4227 11.6667 14 11.6667Z" fill="white"/>
        <path d="M23.3333 20.4167C23.3333 23.3158 23.3333 25.6667 14 25.6667C4.66667 25.6667 4.66667 23.3158 4.66667 20.4167C4.66667 17.5175 8.84567 15.1667 14 15.1667C19.1543 15.1667 23.3333 17.5175 23.3333 20.4167Z" fill="white"/>
        </svg>
        <span className="text-white text-sm">Profile</span>
      </div>
      {isPopupVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-end">
          <div className="bg-white bg-opacity-70 rounded-t-xl w-full max-w-md mx-auto p-4 backdrop-blur-sm">
            <div className="flex flex-col space-y-4">
              <div className="flex items-center space-x-4">
                <div className="bg-blue-500 p-2 rounded-full">
                  <svg className="w-6 h-6 text-white" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_2004_16)">
                  <path d="M15.1667 3.5C15.464 3.50033 15.75 3.61419 15.9663 3.81832C16.1825 4.02245 16.3126 4.30145 16.33 4.59829C16.3475 4.89514 16.2509 5.18744 16.06 5.41547C15.8692 5.6435 15.5984 5.79005 15.3032 5.82517L15.1667 5.83333H5.83333V22.1667H22.1667V12.8333C22.167 12.536 22.2809 12.25 22.485 12.0337C22.6891 11.8175 22.9681 11.6874 23.265 11.67C23.5618 11.6525 23.8541 11.7491 24.0821 11.94C24.3102 12.1308 24.4567 12.4016 24.4918 12.6968L24.5 12.8333V22.1667C24.5002 22.7553 24.2779 23.3223 23.8776 23.754C23.4773 24.1856 22.9287 24.45 22.3417 24.4942L22.1667 24.5H5.83333C5.24466 24.5002 4.67767 24.2779 4.24603 23.8776C3.81438 23.4773 3.54998 22.9287 3.50583 22.3417L3.5 22.1667V5.83333C3.49981 5.24466 3.72214 4.67767 4.12241 4.24603C4.52269 3.81438 5.07132 3.54998 5.65833 3.50583L5.83333 3.5H15.1667ZM22.4502 3.90017C22.6601 3.69093 22.9418 3.56945 23.2381 3.5604C23.5344 3.55135 23.823 3.65542 24.0453 3.85146C24.2677 4.0475 24.407 4.32081 24.4351 4.61589C24.4632 4.91096 24.378 5.20568 24.1967 5.44017L24.0998 5.551L12.5498 17.0998C12.3399 17.3091 12.0582 17.4306 11.7619 17.4396C11.4656 17.4486 11.177 17.3446 10.9547 17.1485C10.7323 16.9525 10.593 16.6792 10.5649 16.3841C10.5368 16.089 10.622 15.7943 10.8033 15.5598L10.9002 15.4502L22.4502 3.90017Z" fill="white"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_2004_16">
                  <rect width="28" height="28" fill="white"/>
                  </clipPath>
                  </defs>
                  </svg>
                </div>
                <span className="text-black text-sm">Создать задачу</span>
              </div>
              <div className="flex items-center space-x-4">
                <div className="bg-green-500 p-2 rounded-full">
                  <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14m-7-7v14"></path>
                  </svg>
                </div>
                <span className="text-black text-sm">Создать проект</span>
              </div>
            </div>
            <div className="flex justify-center mt-4">
              <button onClick={togglePopup} style={{marginBottom: "8px"}} className="bg-blue-500 p-2 rounded-full">
                <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BottomMenu;