import React from 'react';

interface TaskDetailsPopupProps {
  task: { title: string; time: string };
  onClose: () => void;
}

const TaskDetailsPopup: React.FC<TaskDetailsPopupProps> = ({ task, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 transition-opacity duration-300 ease-in-out">
      <div className="relative bg-white p-8 rounded-3xl shadow-xl max-w-sm w-full">
        {/* Close Button */}
        <button 
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 transition-colors duration-200"
          onClick={onClose}
          aria-label="Close"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        
        <h2 className="text-3xl font-semibold text-gray-900 mb-4">{task.title}</h2>
        <p className="text-xl text-gray-500 mb-6">{task.time}</p>
        <p className="text-lg text-gray-700">description</p>
      </div>
    </div>
  );
};

export default TaskDetailsPopup;