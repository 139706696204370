import React from 'react';
import TaskItem from './TaskItem';

interface TaskListProps {
  title: string;
  tasks: { title: string; time: string }[];
  onDetailsClick: (task: { title: string; time: string }) => void;
}

const TaskList: React.FC<TaskListProps> = ({ title, tasks, onDetailsClick }) => {
  return (
    <div className="mb-8 pt-4">
      <div className="text-white mb-4">{`Project: ${title}`}</div>
      {tasks.map((task, index) => (
        <TaskItem
          key={index}
          title={task.title}
          time={task.time}
          onDetailsClick={onDetailsClick}
        />
      ))}
    </div>
  );
};

export default TaskList;