import React, { useState } from 'react';
import Calendar from './components/Calendar';
import TaskList from './components/TaskList';
import FloatingButton from './components/FloatingButton';
import TaskDetailsPopup from './components/TaskDetailsPopup';
import BottomMenu from './components/BottomMenu'; // Import BottomMenu

const App: React.FC = () => {
  const [selectedTask, setSelectedTask] = useState<{ title: string; time: string } | null>(null);

  const handleDetailsClick = (task: { title: string; time: string }) => {
    setSelectedTask(task);
  };

  const closePopup = () => {
    setSelectedTask(null);
  };

  const dailyRoutineTasks = [
    { title: 'Clean the house', time: 'Monday 12:00', description: 'Clean the entire house including the living room, kitchen, and bedrooms.' },
    { title: 'Buy fruits', time: 'Monday 12:00', description: 'Purchase apples, bananas, and oranges from the grocery store.' },
    { title: 'Walk with a dog', time: 'Monday 12:00', description: 'Take the dog for a 30-minute walk in the park.' },
  ];

  const newWebsiteTasks = [
    { title: 'Finish 3 services pages', time: 'Monday 12:00', description: 'Complete the design and content for the 3 service pages on the website.' },
    { title: 'Buy hosting', time: 'Monday 12:00', description: 'Purchase a hosting plan for the new website.' },
  ];

  return (
    <>
      <div className="min-h-screen bg-black flex flex-col">
        <div className='p-6 flex-auto'>
          <Calendar />
          <TaskList title="daily routine" tasks={dailyRoutineTasks} onDetailsClick={handleDetailsClick} />
          <TaskList title="new website" tasks={newWebsiteTasks} onDetailsClick={handleDetailsClick} />
          {/* <FloatingButton /> */}
          
          {selectedTask && <TaskDetailsPopup task={selectedTask} onClose={closePopup} />}
        </div>
        
        <BottomMenu />   {/* Add the BottomMenu component */}
      </div>
    </>
  );
};

export default App;
